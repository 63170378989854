import { Component, OnInit } from '@angular/core';
import {toast} from 'angular2-materialize';

import { Notification } from '../model/notification';
import { Book } from '../model/book';

import { NotificationService } from '../service/notification.service';
import { BookService } from '../service/book.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  title = 'Push-Benachrichtigungen';

  notification: Notification = this.notificationService.createNew();
  isSending = false;
  isValid = true;

  books: Book[];
  languages: string[] = [];
  isBooksLoading = false;
  selectedBook: number = null;
  selectedLanguage: string = null;
  showLanguageSelect = false;

  constructor(private notificationService: NotificationService,
              private bookService: BookService) { }

  ngOnInit() {
    this.loadBookList();
  }

  loadBookList() {
    this.isBooksLoading = true;
    this.bookService.getBookOverview().subscribe((books) => {
      this.isBooksLoading = false;
      this.books = books;
    }, (error) => {
      this.isBooksLoading = false;
      toast('Problem beim Laden der Bücher!', 5000);
    });
  }

  onBookSelect(event: any) {
    this.languages = [];
    this.selectedLanguage = null;
    const book = this.books.find(x => x.id === +event);
    if (book) {
      this.languages = book.languageList;
      this.showLanguageSelect = true;
    } else {
      this.showLanguageSelect = false;
    }
  }

  sendNotification() {
    if (this.notification.title && this.notification.text) {
      this.isSending = true;
      this.notification.bookId = this.selectedBook;
      this.notification.language = this.selectedLanguage;
      this.notificationService.createNotification(this.notification).subscribe((notification) => {
        this.isSending = false;
        toast('Push-Benachrichtigung erfolgreich versendet!', 5000);
      }, (error) => {
        this.isSending = false;
        toast('Problem beim Versenden der Push-Benachrichtigung!', 5000);
      });
    } else {
      this.isValid = false;
    }
  }

}
