
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from '../shared/basedata.service';
import { AuthenticationService } from '../auth/authentication.service';

import { Category } from "../model/category";
import { CategoryInfo } from "../model/category-info";
import { Image } from "../model/image";
import { HttpClient, HttpResponse } from '@angular/common/http';
 
@Injectable()
export class CategoryService extends BaseDataService {
    
    private categoryUrl = '/api/category';

    constructor(private http: HttpClient,
        protected authService: AuthenticationService) {
        super(authService)
    }

    public getCategoryUrl() {
        return super.getFullUrl(this.categoryUrl);
    }

    // GET api/category/categorybychapter/5
    getCategoryByChapter(id: number): Observable<Category[]> {
        let endpoint = this.getCategoryUrl() + '/bychapter/' + id;
        return this.http
            .get<Category[]>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // GET api/category/categorybyid/5
    getCategory(id: number): Observable<Category> {
        let endpoint = this.getCategoryUrl() + '/' + id;
        return this.http
            .get<Category>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // PUT api/category/
    updateCategory(category: Category): Observable<Category> {
        return this.http
            .put<Category>(this.getCategoryUrl(), category, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // POST api/category
    createCategory(category: Category): Observable<Category> {
        return this.http
            .post<Category>(this.getCategoryUrl(), category, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // DELETE api/category
    deleteCategory(id: number): Observable<Category> {
        let endpoint = this.getCategoryUrl() + '/' + id;
        return this.http
            .delete<Category>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // POST api/category/{id}/image
    postImage(id: number, image: File): Observable<string> {
        let endpoint = this.getCategoryUrl() + '/' + id + '/image';
        return this.http
            .post<string>(endpoint, image, { headers: this.getUploadHeaders(image.type) }).pipe(
            catchError(this.handleError));

    }

    // DELETE api/category/{id}/image
    deleteImage(id: number): Observable<any> {
        let endpoint = this.getCategoryUrl() + '/' + id + '/image';
        return this.http
            .delete(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // POST api/category/{id}/csv
    importVocable(id: number, csv: File): Observable<string> {
        let endpoint = this.getCategoryUrl() + '/' + id + '/csv';
        return this.http
            .post<string>(endpoint, csv, { headers: this.getUploadHeaders(csv.type) }).pipe(
            catchError(this.handleError));
    }

    createNew(): Category {
        return Category.create();
    }
}