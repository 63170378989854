﻿export class Category {
    id: number;
    name: string = '';
    orderNumber: number;
    imagePath: string;
    chapterId: number;

    public static create(): Category {
        let r = new Category();
        return r;
    }
}