import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { toast } from 'angular2-materialize';

import { Book } from '../model/book';
import { BookService } from '../service/book.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'all-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.css']
})
export class BooksComponent implements OnInit, AfterViewChecked {
    title = 'Alle Bücher';
    isLoading = false;
    isError = false;

    book: Book;
    books: Book[];
    selectedBook: Book;

    imagePath: string = this.bookService.getBookUrl();

    constructor(private router: Router, private bookService: BookService) { }

    // get and refresh books
    refreshBooks() {
        this.isLoading = true;
        this.bookService.getBookOverview().subscribe((items) => {
            this.isLoading = false;
            items.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                } else if (a.name > b.name) {
                  return 1;
                } else {
                  return 0;
                }
            });
            this.books = items;
        }, (error) => {
            this.isLoading = false;
            this.isError = true;
            toast('Fehler beim Laden der Daten', 5000);
        })
    }

    onRefreshClick() {
        this.refreshBooks();
    }

    // add new book
    onSubmit() {
        this.bookService.createBook(this.book).subscribe((items) => {
            this.refreshBooks();
            toast('Buch wurde erfolgreich angelegt', 5000);
        }, (error) => {
            this.refreshBooks();
            toast('Beim Anlegen ist ein Fehler aufgetretten', 5000);
        });
    }

    // delete book
    deleteBook(id: number) {
        this.deleteBookCover(id);
        this.bookService.deleteBook(id).subscribe((items) => {
            toast('Das Buch wurde erfolgreich gelöscht', 5000);
            this.refreshBooks();
        }, error => {
            toast('Das Buch konnte nicht gelöscht werden', 5000);
        });
    }

    deleteBookCover(id: number) {
        this.bookService.deleteCover(id).subscribe(() => {
            toast('Coverbild gelöscht', 5000);
        }, (error) => {
            toast('Coverbild konnte nicht gelöscht werden', 5000);
        });
    }

    onDeleteClick(id: number) {
        this.deleteBook(id);
    }

    onDeleteConfirmationModal(book: Book) {
        this.selectedBook = book;
    }

    ngOnInit() {
        this.book = this.bookService.createNew();
        this.refreshBooks();
    }

    // important for book items from book loop
    ngAfterViewChecked() {
        (<any>$('.modal-trigger')).leanModal();
    }

    gotoDetail(id: number) {
        this.router.navigate(['/detail', id]);
    }
}
