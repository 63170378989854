import { Component, OnChanges, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { toast, MaterializeDirective } from 'angular2-materialize';

import { Exercise, PairOptionItem } from '../../model/exercise';

import { ExerciseService } from '../../service/exercise.service';

@Component({
  selector: 'app-find-pairs',
  templateUrl: './find-pairs.component.html',
  styleUrls: ['./find-pairs.component.css']
})
export class FindPairsComponent implements OnChanges {

  @Input() exerciseToEdit: Exercise;
  @Input() selectedCategory: number;

  findPairsForm: FormGroup;

  isEditExerciseLoading: boolean = false;

  position: boolean;
  showSeparator: boolean;
  removeSeparator: boolean;
  wrongPair: boolean = false;

  constructor(private exerciseServie: ExerciseService,
              private _fb: FormBuilder
  ) {
    this.createForm();
  }

  createForm() {
    this.findPairsForm = this._fb.group({
      id: [],
      exerciseType: [],
      label: [],
      categoryId: [],
      choiceOptions: [null],
      pairOptions: this._fb.group({
        label: [],
        itemLabelPosition: [],
        items: this._fb.array([]),
        labelPosition: [],
        showSeparator: [],
        removeSeparator: []
      }),
      sortOptions: [null]
    });
  }

  ngOnChanges() {
    this.findPairsForm.reset({
      label: this.exerciseToEdit.label,
      pairOptions: {
        label: this.exerciseToEdit.pairOptions.label,
        itemLabelPosition: this.exerciseToEdit.pairOptions.itemLabelPosition,
        labelPosition: this.exerciseToEdit.pairOptions.labelPosition,
        showSeparator: {
          value: this.exerciseToEdit.pairOptions.showSeparator,
          disabled: this.exerciseToEdit.pairOptions.removeSeparator},
        removeSeparator: {
          value: this.exerciseToEdit.pairOptions.removeSeparator,
          disabled: this.exerciseToEdit.pairOptions.showSeparator
        }
      }
    });
    // check itemLabelPosition value from DB
    if(this.exerciseToEdit.pairOptions.itemLabelPosition == 0) {
      this.position = true;
    } else {
      this.position = false;
    }
    // get showSeparator value from DB
    this.showSeparator = this.exerciseToEdit.pairOptions.showSeparator;
    this.removeSeparator = this.exerciseToEdit.pairOptions.removeSeparator;
    this.setOptionItems(this.exerciseToEdit.pairOptions.items);
  }

  onSubmit() {
    this.exerciseToEdit = this.prepareSaveForm();
    this.exerciseServie.updateExercise(this.exerciseToEdit).subscribe((exercise) => {
      toast('Änderungen wurden erfolgreich gespeichert', 5000);
      this.refreshEditData(this.exerciseToEdit.id);
    }, (error) => {
      toast('Änderungen konnten nicht gespeichert werden', 5000);
    });
  }

  refreshEditData(id: number) {
    this.isEditExerciseLoading = true;
    this.exerciseServie.getExercise(id).subscribe((exercise) => {
      this.isEditExerciseLoading = false;
      this.exerciseToEdit = exercise;
    }, (error) => {
      this.isEditExerciseLoading = false;
      toast('Beim Aktualisieren ist ein Fehler aufgetretten', 5000);
    });
  }

  onReset() {
    this.ngOnChanges();
  }

  // fill FormArray items with conteng
  setOptionItems(items: PairOptionItem[]) {
    const options = items.map(item => this._fb.group(item));
    const optionsArray = this._fb.array(options);
    this.findPairsForm.setControl('items', optionsArray);
  }

  get items(): FormArray {
    return this.findPairsForm.get('items') as FormArray;
  }

  addNewOption() {
    const item: PairOptionItem = {
      labelOrderNumber: this.items.length+1,
      label: '',
      textOrderNumber: this.items.length+1,
      text: ''
    };
    this.items.push(this._fb.group(item));
  }

  addNewWrongOption() {
    this.wrongPair = true;
    const wrongItem: PairOptionItem = {
      labelOrderNumber: null,
      label: null,
      textOrderNumber: this.items.length+1,
      text: ''
    };
    this.items.push(this._fb.group(wrongItem));
  }

  removeOptionItem(i: number) {
    this.items.removeAt(i);
  }

  // set random initialOrderNumber
  randomizeSortItems() {
    const items = this.findPairsForm.get('items').value;
    const array = [];
    const randomizedItems: any[] = [];
    while(array.length < items.length) {
      const random = Math.ceil(Math.random()*items.length);
      if(array.indexOf(random) > -1) continue;
      array[array.length] = random;
      if(items[array.length-1].labelOrderNumber && items[array.length-1].label) {
        const item: PairOptionItem = {
          labelOrderNumber: items[array.length-1].labelOrderNumber,
          label: items[array.length-1].label,
          textOrderNumber: random,
          text: items[array.length-1].text
        };
        randomizedItems.push(item);
      } else {
        const item: PairOptionItem = {
          labelOrderNumber: null,
          label: null,
          textOrderNumber: random,
          text: items[array.length-1].text
        };
        randomizedItems.push(item);
      }
    }
    this.setOptionItems(randomizedItems);
  }

  prepareSaveForm(): Exercise {
    // get full value from Form
    const formModel = this.findPairsForm.value;

    // get all PairOptionItems from Form
    const deepItemsCopy: PairOptionItem[] = formModel.items.map(
        (item: PairOptionItem) => Object.assign({}, item)
    );

    // bring Form value in right order
    const saveForm: Exercise = {
      id: this.exerciseToEdit.id,
      exerciseType: 'FindPairs',
      label: formModel.label,
      categoryId: this.selectedCategory,
      choiceOptions: null,
      pairOptions: {
        label: formModel.pairOptions.label,
        itemLabelPosition: formModel.pairOptions.itemLabelPosition,
        items: deepItemsCopy,
        labelPosition: formModel.pairOptions.labelPosition,
        showSeparator: formModel.pairOptions.showSeparator,
        removeSeparator: formModel.pairOptions.removeSeparator
      },
      sortOptions: null
    };

    return saveForm;
  }

  changeItemLabelPosition($event) {
    // if value = 0, then itemLabelPosition left
    // if value = 1, then itemLabelPosition right
    if($event.target.value == 0) {
      this.position = true;
    } else {
      this.position = false;
    }
  }

  changeShowSeparator($event) {
    if($event.target.checked) {
      this.showSeparator = true;
      this.removeSeparator = false;
      this.findPairsForm.get('pairOptions').get('removeSeparator').disable();
    } else {
      this.showSeparator = false;
      this.findPairsForm.get('pairOptions').get('removeSeparator').enable();
    }
  }

  changeRemoveSeparator($event) {
    if($event.target.checked) {
      this.removeSeparator = true;
      this.showSeparator = false;
      this.findPairsForm.get('pairOptions').get('showSeparator').disable();
    } else {
      this.removeSeparator = false;
      this.findPairsForm.get('pairOptions').get('showSeparator').enable();
    }
  }

}
