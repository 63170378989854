export class Exercise {
    id: number;
    exerciseType: string;
    label: string;
    categoryId: number;
    choiceOptions: ChoiceOption;
    pairOptions: PairOption;
    sortOptions: SortOption;

    public static create() : Exercise {
        let r = new Exercise();
        r.choiceOptions = new ChoiceOption();
        r.pairOptions = new PairOption();
        r.sortOptions = new SortOption();
        return r;
    }
}

export class ChoiceOption {
    label: string;
    items: ChoiceOptionItem[];
    pattern: string;
}

export class ChoiceOptionItem {
    orderNumber: number = 0;
    text: string = '';
    isCorrectAnswer: boolean = false;
}

export class PairOption {
    label: string = '';
    itemLabelPosition: number = 0;
    items: PairOptionItem[];
    labelPosition: number = 0;
    showSeparator: boolean = false;
    removeSeparator: boolean = false;
}

export class PairOptionItem {
    labelOrderNumber: number = 0;
    label: string = '';
    textOrderNumber: number = 0;
    text: string = '';
}

export class SortOption {
    label: string;
    initialItems: InitialSortOptionItem[];
    items?: EditSortOptionItem[] = [];
    pattern: string;
    solutions: SortOptionSolutionItem[];
}

export class SortOptionSolutionItem {
    items: SortOptionItem[];
}

export class SortOptionItem {
    orderNumber: number = 0;
    text: string = '';
}

export class InitialSortOptionItem extends SortOptionItem {
    correctOrderNumber: number = 0;
}

export class EditSortOptionItem extends SortOptionItem {
    initialOrderNumber: number = 0;
    correctOrderNumber: number = 0;
}