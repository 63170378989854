
import {catchError} from 'rxjs/operators';
﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from '../shared/basedata.service';
import { AuthenticationService } from '../auth/authentication.service';

import { Translation } from '../model/translation';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TranslationService extends BaseDataService {
    
    private translationUrl = '/api/translation';

    constructor(private http: HttpClient,
        protected authService: AuthenticationService) {
        super(authService)
    }

    private getTranslationUrl() {
        return super.getFullUrl(this.translationUrl);
    }

    // GET api/translation/{id}
    getTranslation(id: number): Observable<Translation> {
        let endpoint = this.getTranslationUrl() + '/' + id;
        return this.http
            .get<Translation>(endpoint, { headers: this.getHeaders() }).pipe(
           catchError(this.handleError));
    }

    // GET api/translation/{vocableid}/{languageCode}
    getTranslationByLanguage(id: number, language: string): Observable<Translation> {
        let endpoint = this.getTranslationUrl() + '/' + id + '/' + language;
        return this.http
            .get<Translation>(endpoint, { headers: this.getHeaders() }).pipe(
           catchError(this.handleError));
    }

    // GET api/translation/bycategory/{categoryid}/{language}
    getTranslationByCategory(id: number, language: string): Observable<Translation[]> {
        let endpoint = this.getTranslationUrl() + '/bycategory/' + id + '/' + language;
        return this.http
            .get<Translation[]>(endpoint, { headers: this.getHeaders() }).pipe(
           catchError(this.handleError));
    }

    // PUT api/translation/
    updateTranslation(translation: Translation): Observable<Translation> {
        return this.http
            .put<Translation>(this.getTranslationUrl(), translation, { headers: this.getHeaders() }).pipe(
           catchError(this.handleError));
    }

    // POST api/translation
    createTranslation(translation: Translation): Observable<Translation> {
        return this.http
            .post<Translation>(this.getTranslationUrl(), translation, { headers: this.getHeaders() }).pipe(
           catchError(this.handleError));
    }

    // DELETE api/translation/{vocableId}/{language}
    deleteTranslation(id: number, language: string): Observable<Translation> {
        let endpoint = this.getTranslationUrl() + '/' + id + '/' + language;
        return this.http
            .delete<Translation>(endpoint, { headers: this.getHeaders() }).pipe(
           catchError(this.handleError));
    }

    createNew(): Translation {
        return Translation.create();
    }
}