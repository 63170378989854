import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MaterializeModule } from 'angular2-materialize';

import { AppRouting } from './app.routing';

import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';

import { LoginComponent } from './auth/login.component';

import { CategoryComponent } from './category/category.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BookComponent } from './book/book.component';
import { BooksComponent } from './books/books.component';
import { VocablesComponent } from './vocables/vocables.component';
import { ExerciseComponent } from './exercise/exercise.component';

import { BookService } from './service/book.service';
import { ChapterService } from './service/chapter.service';
import { CategoryService } from './service/category.service';
import { VocableService } from './service/vocable.service';
import { TranslationService } from './service/translation.service';
import { LanguageService } from './service/language.service';
import { ExerciseService } from './service/exercise.service';
import { NotificationService } from './service/notification.service';
import { MultipleChoiceComponent } from './exercise/multiple-choice/multiple-choice.component';
import { FindPairsComponent } from './exercise/find-pairs/find-pairs.component';
import { BringInOrderComponent } from './exercise/bring-in-order/bring-in-order.component';
import { FillTheGapComponent } from './exercise/fill-the-gap/fill-the-gap.component';
import { ChoiceOptionComponent } from './exercise/multiple-choice/choice-option.component';
import { PairOptionComponent } from './exercise/find-pairs/pair-option.component';
import { SortOptionComponent } from './exercise/bring-in-order/sort-option.component';
import { GapItemComponent } from './exercise/fill-the-gap/gap-item.component';
import { VocablesEditComponent } from './vocables/vocables-edit/vocables-edit.component';
import { TranslationsEditComponent } from './vocables/translations-edit/translations-edit.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AuthenticationService } from './auth/authentication.service';
import { AuthGuard } from './auth/authguard.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavigationComponent,
    CategoryComponent,
    DashboardComponent,
    BookComponent,
    BooksComponent,
    VocablesComponent,
    ExerciseComponent,
    MultipleChoiceComponent,
    MultipleChoiceComponent,
    FindPairsComponent,
    BringInOrderComponent,
    FillTheGapComponent,
    ChoiceOptionComponent,
    PairOptionComponent,
    SortOptionComponent,
    GapItemComponent,
    VocablesEditComponent,
    TranslationsEditComponent,
    NotificationsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRouting,
    // LoginRoutingModule,
    MaterializeModule
  ],
  providers: [
    AuthGuard,
    BookService,
    ChapterService,
    CategoryService,
    VocableService,
    TranslationService,
    LanguageService,
    ExerciseService,
    NotificationService,
    AuthenticationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
