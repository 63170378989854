﻿export class Vocable {
    id: number;
    audioPath: string;
    imagePath: string;
    sourceText: string = '';
    sourceTextFast: string = '';
    sourceTextIntensive: string = '';
    categoryId: number;

    public static create(): Vocable {
        let r = new Vocable();
        return r;
    }
}