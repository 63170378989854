import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { toast } from 'angular2-materialize';

import { CategoryInfo } from '../model/category-info';
import { ChapterInfo } from '../model/chapter-info';
import { Category } from '../model/category';
import { Vocable } from '../model/vocable';
import { Translation } from '../model/translation';
import { VocableInfo } from '../model/vocable-info';
import { Language } from '../model/language';
import { BookInfo } from '../model/book-info';
import { Book } from '../model/book';

import { CategoryService } from '../service/category.service';
import { ChapterService } from '../service/chapter.service';
import { VocableService } from '../service/vocable.service';
import { TranslationService } from '../service/translation.service';
import { LanguageService } from '../service/language.service';
import { BookService } from '../service/book.service';

@Component({
  selector: 'category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit, AfterViewInit {

    id: number;

    category: Category;

    isCategoryLoading: boolean = false;
    categoryEditMode: boolean = false;

    fileToUpload: File;

    categoryImagePath: string = this.categoryService.getCategoryUrl();

    constructor(
        private route: ActivatedRoute,
        private categoryService: CategoryService,
        private vocableService: VocableService,
        private translationService: TranslationService,
        private languageService: LanguageService,
        private bookService: BookService,
        private chapterService: ChapterService,
        private router: Router
    ) { }

    ngAfterViewInit(): void {
        // JS Code for dropdown & Modal
        (<any>$('.dropdown-button')).material_select();
        (<any>$('.modal-trigger')).leanModal();
    }

    handleCategoryEditMode(categoryEditMode: boolean) {
        categoryEditMode ? this.categoryEditMode = false : this.categoryEditMode = true;
    }

    // upload category cover
    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }

    ngOnInit() {
        this.route.params.subscribe(p => this.id = p['id']);
        this.loadCategoryInfo(this.id);
    }

    loadCategoryInfo(id: number) {
        this.isCategoryLoading = true;
        this.categoryService.getCategory(id).subscribe((category) => {
            this.isCategoryLoading = false;
            this.category = category;
        }, (error) => {
            this.isCategoryLoading = false;
            toast('Beim Laden der Kategorieinformation ist ein Fehler aufgetretten', 5000);
        });
    }

    saveCategoryChanges(categoryEditMode: boolean) {
        this.categoryService.updateCategory(this.category).subscribe((category) => {
            this.loadCategoryInfo(this.id);
            this.handleCategoryEditMode(categoryEditMode);
            toast('Die Kategorie wurde erfolgreich aktualisiert', 5000);
        }, (error) => {
            toast('Beim Aktualisieren der Kategorieinformationen ist ein Fehler aufgetretten', 5000);
        });
        if(this.fileToUpload) {
            this.uploadCategoryCover();
        }
    }

    uploadCategoryCover() {
        this.categoryService.postImage(this.category.id, this.fileToUpload).subscribe(() => {
            this.fileToUpload = null;
            toast('Kategoriebild wurde erfolgreich hochgeladen', 5000);
        }, (error) => {
            toast('Kategoriebild konnte nicht gespeichert werden', 5000);
        });
    }

    deleteCategoryCover(id: number) {
        this.categoryService.deleteImage(id).subscribe(() => {
            toast('Kategoriebild wurde gelöscht', 5000);
        }, (error) => {
            toast('Beim Löschen des Kategoriebildes ist ein Fehler aufgetretten', 5000);
        });
    }

}
