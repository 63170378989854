import { Component, OnChanges, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { toast } from 'angular2-materialize';

import {
  EditSortOptionItem, Exercise, InitialSortOptionItem,
  SortOptionItem, SortOptionSolutionItem
} from '../../model/exercise';

import { ExerciseService } from '../../service/exercise.service';

@Component({
  selector: 'app-bring-in-order',
  templateUrl: './bring-in-order.component.html',
  styleUrls: ['./bring-in-order.component.css']
})
export class BringInOrderComponent implements OnChanges {

  @Input() exerciseToEdit: Exercise;
  @Input() selectedCategory: number;

  bringInOrderForm: FormGroup;

  isEditExerciseLoading: boolean = false;

  backspaceCount: number = 0;

  addNewSolutionMode: boolean = false;
  newSolutionSentence: SortOptionItem[] = [];

  @ViewChild('solutionSentence')
  solutionSentence: ElementRef;

  constructor(private exerciseService: ExerciseService,
              private _fb: FormBuilder
  ) {
    this.createForm();
  }

  createForm() {
    this.bringInOrderForm = this._fb.group({
      id: [],
      exerciseType: [],
      label: [],
      choiceOptions: [null],
      pairOptions: [null],
      sortOptions: this._fb.group({
        label: [],
        sentence: [], // only for edit
        items: this._fb.array([]),
        sentenceEnd: [], // only for edit
        pattern: [],
        solutionSentence: [] // only for edit
      })
    });
  }

  ngOnChanges() {
    this.resetForm();
    this.prepareSentenceItems();
    this.setOptionItems(this.exerciseToEdit.sortOptions.items);
    this.randomizeSortItems();
  }

  resetForm() {
    this.bringInOrderForm.reset({
      label: this.exerciseToEdit.label,
      sortOptions: {
        label: this.exerciseToEdit.sortOptions.label,
        sentenceEnd: this.getSentenceEnd(this.exerciseToEdit.sortOptions.pattern),
        pattern: this.exerciseToEdit.sortOptions.pattern
      }
    });
  }

  prepareSentenceItems() {
    if (this.exerciseToEdit.sortOptions.solutions.length !== 0 && this.exerciseToEdit.sortOptions.initialItems.length !== 0) {
      let firstSolution: SortOptionSolutionItem = { items: [] };
      let positionToDelete: number;
      // following block was created as helper for checking of main edit sentence
      let sortedInitialArray: InitialSortOptionItem[] = [];
      this.exerciseToEdit.sortOptions.initialItems.forEach(item => {
        const newItem: InitialSortOptionItem = {
          text: item.text,
          orderNumber: item.correctOrderNumber,
          correctOrderNumber: 0
        };
        sortedInitialArray[item.correctOrderNumber-1] = newItem;
      });
      this.exerciseToEdit.sortOptions.solutions = this.exerciseToEdit.sortOptions.solutions.map((solution, solutionIndex) => {
        // begin sort all solutions by orderNumber and set orderNumber as index
        const newSortedSolutionArray: SortOptionSolutionItem = { items: [] };
        solution.items.forEach((solutionItem, itemIndex) => {
          newSortedSolutionArray.items[solutionItem.orderNumber-1] = solutionItem;
        });
        return solution = newSortedSolutionArray;
        // end sort all solutions
      });
      // checking for main sentence
      this.exerciseToEdit.sortOptions.solutions.forEach((solution, solutionIndex) => {
        // begin check if one solution array have match with main sentence, if yes set this index to positionToDelete
        if (solution.items.every((item, index) => (item.text === sortedInitialArray[index].text && item.orderNumber === sortedInitialArray[index].orderNumber))) {
          firstSolution.items = solution.items;
          positionToDelete = solutionIndex;
        }
        // end check matching
      });
      if (firstSolution) {
        this.exerciseToEdit.sortOptions.solutions.splice(positionToDelete, 1);
        this.exerciseToEdit.sortOptions.items = this.createOptionItems(
          firstSolution.items,
          this.exerciseToEdit.sortOptions.initialItems);
      }
    }
  }

  onSubmit() {
    this.exerciseToEdit = this.prepareSaveForm();
    this.exerciseService.updateExercise(this.exerciseToEdit).subscribe((exercise) => {
      toast('Änderungen wurden erfolgreich gespeichert', 5000);
      this.refreshEditData(this.exerciseToEdit.id);
    }, (error) => {
      toast('Änderungen konnten nicht gespeichert werden', 5000);
    });
  }

  refreshEditData(id: number) {
    this.isEditExerciseLoading = true;
    this.exerciseService.getExercise(id).subscribe((exercise) => {
      this.isEditExerciseLoading = false;
      this.exerciseToEdit = exercise;
      this.prepareSentenceItems();
    }, (error) => {
      this.isEditExerciseLoading = false;
      toast('Beim Aktualisieren ist ein Fehler aufgetretten', 5000);
    });
  }

  onReset() {
    this.ngOnChanges();
  }

  getSentenceEnd(pattern: string) {
    if(pattern && pattern.length > 10) {
      return pattern.substr(pattern.length-1, 1);
    }
  }

  createOptionItems(solutionItems: SortOptionItem[], initialItems: InitialSortOptionItem[]): EditSortOptionItem[] {
    const items: EditSortOptionItem[] = [];
    solutionItems.forEach((solutionItem, index) => {
      const item: EditSortOptionItem = {
        orderNumber: solutionItem.orderNumber,
        initialOrderNumber: initialItems[index].orderNumber,
        correctOrderNumber: initialItems[index].correctOrderNumber,
        text: solutionItem.text
      };
      items.push(item);
    });
    return items;
  }

  // fill FormArray items with content
  setOptionItems(items: EditSortOptionItem[]) {
    const rightOrder = [];
    let optionsArray;
    if (items && items.length !== 0) {
      items.map(item => {rightOrder[item.orderNumber-1] = item});
      const options = rightOrder.map(item => this._fb.group(item));
      optionsArray = this._fb.array(options);
    } else {
      optionsArray = this._fb.array([]);
    }
    this.bringInOrderForm.setControl('items', optionsArray);
  }

  get items(): FormArray {
    return this.bringInOrderForm.get('items') as FormArray;
  }

  addOptionItem(sentence: HTMLInputElement) {
    if(sentence.value) {
      const item: EditSortOptionItem = {
        orderNumber: this.items.length+1,
        initialOrderNumber: this.items.length+1,
        correctOrderNumber: this.items.length+1,
        text: sentence.value
      };
      this.items.push(this._fb.group(item));
      // clear input value by pressing enter
      sentence.value = null;
    }
  }

  removeOptionItem(i: number) {
    this.items.removeAt(i);
  }

  onKeyUp($event, newSolution: boolean = false) {
    if($event.key == 'Enter') {
      // by pressing enter next button runs automatically
      // next button runs addOptionItem()

      if (newSolution) {
        this.addSolutionSentenceItem(this.solutionSentence.nativeElement);
      }
      // clear input value
      $event.target.value = '';
    }

    // by press backspace key, count key repeat
    // if count = 2, then get value of last element and remove this from items array
    // edit value, and push update value to items array
    if($event.key == 'Backspace' && !$event.target.value) {
      this.backspaceCount++;
      if(this.backspaceCount == 2) {
        if (!newSolution) {
          const word = this.items.at(this.items.length-1).value;
          if (word) {
            $event.target.value = word.text;
            this.items.removeAt(this.items.length-1);
          }
        } else {
          const word = this.newSolutionSentence[this.newSolutionSentence.length-1];
          $event.target.value = word.text;
          this.newSolutionSentence.splice(this.newSolutionSentence.length-1, 1);
        }
        this.backspaceCount = 0;
      }
    }
  }

  toggleSolutionSentenceEditForm() {
    this.addNewSolutionMode = !this.addNewSolutionMode;
  }

  addSolutionSentenceItem(solutionSentence: HTMLInputElement) {
    if(solutionSentence.value) {
      const item: SortOptionItem = {
        orderNumber: this.newSolutionSentence.length+1,
        text: solutionSentence.value
      };
      this.newSolutionSentence.push(item);
      // clear input value by pressing enter
      solutionSentence.value = null;
    }
  }

  addSolutionSentence() {
    const newItems: SortOptionSolutionItem = new SortOptionSolutionItem;
    if (this.newSolutionSentence.length != 0) {
      newItems.items = this.newSolutionSentence;
      this.exerciseToEdit.sortOptions.solutions.push(newItems);
    }
    this.newSolutionSentence = [];
    this.toggleSolutionSentenceEditForm();
  }

  editSolutionSentence(i: number) {
    this.addNewSolutionMode = true;
    this.newSolutionSentence = this.exerciseToEdit.sortOptions.solutions[i].items;
    this.removeSolutionSentence(i);
  }

  removeSolutionSentence(i: number) {
    this.exerciseToEdit.sortOptions.solutions.splice(i, 1);
  }

  cancelSolutionSentenceEditMode() {
    this.addSolutionSentence();
    this.newSolutionSentence = [];
    this.addNewSolutionMode = false;
  }

  // order items by initialOrderNumber
  get wrongSortedItems() {
    const items = this.bringInOrderForm.get('items').value;
    let wrong = [];
    items.map(item => {wrong[item.initialOrderNumber-1] = item});
    // reindex array keys
    wrong = wrong.filter(item => {return item != undefined});
    return wrong;
  }

  // set random initialOrderNumber
  randomizeSortItems() {
    const items = this.bringInOrderForm.get('items').value;
    const array = [];
    const randomizedItems: any[] = [];
    while(array.length < items.length) {
      const random = Math.ceil(Math.random()*items.length);
      if(array.indexOf(random) > -1) continue;
      array[array.length] = random;
      const item: EditSortOptionItem = {
        orderNumber: items[array.length-1].orderNumber,
        initialOrderNumber: random,
        correctOrderNumber: items[array.length-1].orderNumber,
        text: items[array.length-1].text
      };
      randomizedItems.push(item);
    }
    this.setOptionItems(randomizedItems);
  }

  prepareSaveForm(): Exercise {
    // get full value from Form
    const formModel = this.bringInOrderForm.value;

    const initialItems: InitialSortOptionItem[] = [];
    const mainSolutionItems: SortOptionItem[] = [];
    formModel.items.forEach(item => {
      const initialItem: InitialSortOptionItem = {
        orderNumber: item.initialOrderNumber,
        text: item.text,
        correctOrderNumber: item.orderNumber
      };
      const solutionItem: SortOptionItem = {
        orderNumber: item.orderNumber,
        text: item.text
      };
      initialItems.push(initialItem);
      mainSolutionItems.push(solutionItem);
    });

    const solutions: SortOptionSolutionItem[] = [];
    const mainSolution: SortOptionSolutionItem = {
      items: mainSolutionItems
    };
    solutions.push(mainSolution);

    this.exerciseToEdit.sortOptions.solutions.forEach(solutionSentence => {
      const sentence: SortOptionSolutionItem = {
        items: solutionSentence.items
      };
      solutions.push(sentence);
    });

    let patternToSave = '%SOLUTION%';
    if (formModel.sortOptions.sentenceEnd) {
      patternToSave += formModel.sortOptions.sentenceEnd;
    }

    // bring Form value in right order
    const saveForm: Exercise = {
      id: this.exerciseToEdit.id,
      exerciseType: 'BringInOrder',
      label: formModel.label,
      categoryId: this.selectedCategory,
      choiceOptions: null,
      pairOptions: null,
      sortOptions: {
        label: formModel.sortOptions.label,
        pattern: patternToSave,
        initialItems: initialItems,
        solutions: solutions
      }
    };

    return saveForm;
  }

}
