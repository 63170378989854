﻿export class Chapter {
    id: number;
    name: string;
    title: string;
    orderNumber: number;
    imagePath: string;
    bookId: number;

    public static create() : Chapter {
        let r = new Chapter();
        return r;
    }
}