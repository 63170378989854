
import {catchError} from 'rxjs/operators';
﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from '../shared/basedata.service';
import { AuthenticationService } from '../auth/authentication.service';

import { Notification } from '../model/notification';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NotificationService extends BaseDataService {

    private notificationUrl = '/api/message';

    constructor(private http: HttpClient,
                protected authService: AuthenticationService) {
        super(authService)
    }

    private getNotificationUrl() {
        return super.getFullUrl(this.notificationUrl);
    }

    // POST api/message
    createNotification(notification: Notification): Observable<Notification> {
        return this.http
            .post<Notification>(this.getNotificationUrl(), notification, { headers: this.getHeaders() }).pipe(
           catchError(this.handleError));
    }

    createNew(): Notification {
        return Notification.create();
    }
}