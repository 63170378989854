import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { toast, MaterializeDirective } from 'angular2-materialize';

import { Exercise } from '../model/exercise';
import { Book } from '../model/book';
import { Chapter } from '../model/chapter';
import { Category } from '../model/category';

import { ExerciseService } from '../service/exercise.service';
import { BookService } from '../service/book.service';
import { ChapterService } from '../service/chapter.service';
import { CategoryService } from '../service/category.service';

@Component({
  selector: 'exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.css']
})
export class ExerciseComponent implements OnInit, AfterViewChecked {

    title = 'Übungen';
    isError: boolean = false;

    exercise: Exercise;
    exercises: any;
    newExercise: Exercise;
    selectedExercise: number;
    exerciseToDelete: Exercise;
    isExerciseListLoading: boolean = false;
    isExerciseLoading: boolean = false;

    books: Book[];
    selectedBook: number;
    isBookListLoading: boolean = false;

    chapters: Chapter[];
    selectedChapter: number;
    isChapterListLoading: boolean = false;

    categories: Category[];
    selectedCategory: number;

    constructor(private router: Router,
      private exerciseService: ExerciseService,
      private bookService: BookService,
      private chapterService: ChapterService,
      private categoryService: CategoryService
    ) { }

    ngOnInit() {
      this.newExercise = this.exerciseService.createNew();
      this.refreshBookList();
    }

    ngAfterViewChecked() {
      (<any>$('.modal-trigger')).leanModal();
    }

    refreshBookList() {
      this.isBookListLoading = true;
      this.bookService.getBooks().subscribe((books) => {
        this.isBookListLoading = false;
        this.books = books;
      }, (error) => {
        this.isBookListLoading = false;
        this.isError = true;
        toast('Bücher konnten nicht geladen werden', 5000);
      });
    }

    selectBook($event) {
      this.selectedBook = $event.target.value;
      this.selectedChapter = null;
      this.selectedCategory = null;
      this.selectedExercise = null;
      if (this.selectedBook) {
        this.loadChapterList(this.selectedBook);
      }
    }

    loadChapterList(id: number) {
      this.isChapterListLoading = true;
      this.chapterService.getChaptersByBook(id).subscribe((chapters) => {
        this.isChapterListLoading = false;
        this.chapters = chapters;
      }, (error) => {
        this.isChapterListLoading = false;
        toast('Kapitel konnten nicht geladen werden', 5000);
      });
    }

    selectChapter($event) {
      this.selectedChapter = $event.target.value;
      this.selectedCategory = null;
      this.selectedExercise = null;
      if (this.selectedChapter) {
        this.loadCategoryList(this.selectedChapter);
      }
    }

    loadCategoryList(id: number) {
      this.categoryService.getCategoryByChapter(id).subscribe((categries) => {
        this.categories = categries;
      }, (error) => {
        toast('Kategorien konnten nicht geladen werden', 5000);
      });
    }

    selectCategory($event) {
      this.selectedCategory = $event.target.value;
      this.selectedExercise = null;
      if (this.selectedCategory) {
        this.loadExerciseList(this.selectedCategory);
      }
    }

    loadExerciseList(id: number) {
      this.isExerciseListLoading = true;
      this.exerciseService.getExerciseByCategory(id).subscribe((exercises) => {
        this.isExerciseListLoading = false;
        this.exercises = exercises;
      }, (error) => {
        this.isExerciseListLoading = false;
        toast('Übungen konnten nicht geladen werden', 5000);
      });
    }

    selectExerciseToEdit(id: number) {
      this.selectedExercise = id;
      if (this.selectedExercise) {
        this.loadOneExercise(this.selectedExercise);
      }
    }

    selectExerciseToDelete(exercise: Exercise) {
      this.exerciseToDelete = exercise;
    }

    loadOneExercise(id: number) {
      this.isExerciseLoading = true;
      this.exerciseService.getExercise(id).subscribe((exercise) => {
        this.isExerciseLoading = false;
        this.exercise = exercise;
      }, (error) => {
        this.isExerciseLoading = false;
        toast('Übung konnte nicht geladen werden', 5000);
      });
    }

    deleteExercise(id: number) {
      this.selectedExercise = null;
      this.exerciseService.deleteExercise(id).subscribe((exercise) => {
        this.loadExerciseList(this.selectedCategory);
        toast('Übung wurde erfolgreich gelöscht', 5000);
      }, (error) => {
        toast('Übung konnte nicht gelöscht werden', 5000);
      });
    }

    onCreateExercise() {
      this.newExercise.categoryId = this.selectedCategory;
      this.exerciseService.createExercise(this.newExercise).subscribe((exercise) => {
        this.loadExerciseList(this.selectedCategory);
        this.newExercise.label = '';
        this.newExercise.exerciseType = '';
        toast('Übung wurde erfolgreich angelegt', 5000);
      }, (error) => {
          this.newExercise.label = '';
          this.newExercise.exerciseType = '';
        toast('Übung konnte nicht angelegt werden', 5000);
      });
    }

    resetCreateModal() {
      this.newExercise.label = '';
      this.newExercise.exerciseType = '';
    }

}