import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-choice-option',
  templateUrl: './choice-option.component.html',
  styles: []
})
export class ChoiceOptionComponent {

  @Input() choiceItem: FormGroup;
  @Input() index: number;

  constructor() { }

}
