
import {catchError} from 'rxjs/operators';
﻿import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from '../shared/basedata.service';
import { AuthenticationService } from '../auth/authentication.service';

import { Language } from '../model/language';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LanguageService extends BaseDataService {

    private languageUrl = '/api/language';

    constructor(private http: HttpClient,
        protected authService: AuthenticationService) {
        super(authService)
    }

    private getLanguageUrl() {
        return super.getFullUrl(this.languageUrl);
    }

    // GET api/language
    getLanguages(): Observable<Language[]> {
        return this.http
            .get<Language[]>(this.getLanguageUrl(), { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // GET api/language/{code}
    getLanguageName(code: string): Observable<string> {
        let endpoint = this.getLanguageUrl() + '/' + code;
        return this.http
            .get<string>(endpoint, { headers: this.getHeaders() }).pipe(
           catchError(this.handleError));
    }

    // POST api/language
    createLanguage(language: Language): Observable<Language> {
        return this.http
            .post<Language>(this.getLanguageUrl(), language, { headers: this.getHeaders() }).pipe(
           catchError(this.handleError));
    }

    createNew(): Language {
        return Language.create();
    }
}