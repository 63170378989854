
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from '../../environments/environment';
import { defaults } from '../defaults';
import { HttpHeaders } from '@angular/common/http';
import { HttpResponse } from 'selenium-webdriver/http';

export class BaseDataService {
    constructor(protected authService : AuthenticationService) {
    }

    protected getHeaders() : HttpHeaders{
        let headers = defaults.getDefaultHeaders();
        let authHeader = this.authService.getAuthHeaderValue();
        if (authHeader){
            headers = headers.append('Authorization', authHeader);
        }

        return headers;
    }

    protected getUploadHeaders(type): HttpHeaders {
        let headers = defaults.getUploadHeaders(type);
        let authHeader = this.authService.getAuthHeaderValue();
        if (authHeader) {
            headers = headers.append('Authorization', authHeader);
        }
        return headers;
    }

    protected getFullUrl(appRelativeEndpoint: string): string {
        return environment.webAppBaseUrl + appRelativeEndpoint;
    }

    protected handleError (error: HttpResponse | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.body || '';
            const err = error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }
}
