export class Notification {
    title: string;
    text: string;
    bookId: number = null;
    language: string;

    public static create(): Notification {
        const r = new Notification();
        return r;
    }
}
