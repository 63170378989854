﻿export class Translation {
    id: number;
    languageCode: string;
    translation: string;
    advice: string;

    public static create(): Translation {
        let r = new Translation();
        return r;
    }
}