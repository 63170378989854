import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { NavItem } from '../model/nav-item';
import {AuthenticationService} from "../auth/authentication.service";

declare var Layout: any;

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent {
  public nav: NavItem[];

  constructor(private router: Router,
              protected authService: AuthenticationService) {
    this.nav = [
      { name: 'Übersicht', route: 'dashboard', children: <any>null, icon: 'dashboard' },
      { name: 'Bücher', route: 'books', children: <any>null, icon: 'import_contacts' },
      { name: 'Vokabeln', route: 'vocables', children: <any>null, icon: 'translate' },
      { name: 'Übungen', route: 'exercises', children: <any>null, icon: 'school' },
      { name: 'Benachrichtigungen', route: 'notifications', children: <any>null, icon: 'notifications' }
    ];
  }

  onLogout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
