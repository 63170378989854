
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from '../shared/basedata.service';
import { AuthenticationService } from '../auth/authentication.service';

import { Exercise } from "../model/exercise";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ExerciseService extends BaseDataService {

    private exerciseUrl = '/api/exercise';

    constructor(private http: HttpClient,
        protected authService: AuthenticationService) {
        super(authService)
    }

    private getExerciseUrl() {
        return super.getFullUrl(this.exerciseUrl);
    }

    // get exercise by id
    getExercise(id: number): Observable<Exercise> {
        let endpoint = this.getExerciseUrl() + '/' + id;
        return this.http
            .get<Exercise>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // get exercise by category id
    getExerciseByCategory(id: number): Observable<Exercise> {
        let endpoint = this.getExerciseUrl() + '/bycategory/' + id;
        return this.http
            .get<Exercise>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    createExercise(exercise: Exercise): Observable<Exercise> {
        return this.http
            .post<Exercise>(this.getExerciseUrl(), exercise, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    updateExercise(exercise: Exercise): Observable<Exercise> {
        return this.http
            .put<Exercise>(this.getExerciseUrl(), exercise, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    deleteExercise(id: number): Observable<Exercise> {
        let endpoint = this.getExerciseUrl() + '/' + id;
        return this.http
            .delete<Exercise>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    createNew() : Exercise {
        return Exercise.create();
    }

}