
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from '../shared/basedata.service';
import { AuthenticationService } from '../auth/authentication.service';

import { Vocable } from '../model/vocable';
import { VocableInfo } from '../model/vocable-info';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VocableService extends BaseDataService {

    private vocableUrl = '/api/vocable';

    constructor(private http: HttpClient,
        protected authService: AuthenticationService) {
        super(authService)
    }

    private getVocableUrl() {
        return super.getFullUrl(this.vocableUrl);
    }

    // GET api/vocable/bybook/{id}
    getVocableByBook(id: number): Observable<Vocable[]> {
        let endpoint = this.getVocableUrl() + '/bybook/' + id;
        return this.http
            .get<Vocable[]>(endpoint, { headers: this.getHeaders() }).pipe(
                catchError(this.handleError));
    }

    // GET api/vocable/bycategory/{id}
    getVocableByCategory(id: number): Observable<Vocable[]> {
        let endpoint = this.getVocableUrl() + '/bycategory/' + id;
        return this.http
            .get<Vocable[]>(endpoint, { headers: this.getHeaders() }).pipe(
                catchError(this.handleError));
    }

    // GET api/vocable/{id}
    getVocable(id: number): Observable<Vocable> {
        let endpoint = this.getVocableUrl() + '/' + id;
        return this.http
            .get<Vocable>(endpoint, { headers: this.getHeaders() }).pipe(
                catchError(this.handleError));
    }

    // GET api/vocable/info/{id}
    getVocableInfo(id: number): Observable<VocableInfo> {
        let endpoint = this.getVocableUrl() + '/info/' + id;
        return this.http
            .get<VocableInfo>(endpoint, { headers: this.getHeaders() }).pipe(
                catchError(this.handleError));
    }

    // GET api/vocable/info/bycategory/{id}
    getVocableInfoByCategory(id: number): Observable<VocableInfo[]> {
        let endpoint = this.getVocableUrl() + '/info/bycategory/' + id;
        return this.http
            .get<VocableInfo[]>(endpoint, { headers: this.getHeaders() }).pipe(
                catchError(this.handleError));
    }

    // PUT api/vocable
    updateVocable(vocable: Vocable): Observable<Vocable> {
        // TODO: figure out why angular sends the categoryId as a string without an explicit cast
        // that would fail the model binding
        vocable.categoryId = +vocable.categoryId;
        return this.http
            .put<Vocable>(this.getVocableUrl(), vocable, { headers: this.getHeaders() }).pipe(
                catchError(this.handleError));
    }

    // POST api/vocable
    createVocable(vocable: Vocable): Observable<Vocable> {
        // TODO: figure out why angular sends the categoryId as a string without an explicit cast
        // that would fail the model binding
        vocable.categoryId = +vocable.categoryId;
        return this.http
            .post<Vocable>(this.getVocableUrl(), vocable, { headers: this.getHeaders() }).pipe(
                catchError(this.handleError));
    }

    // DELETE api/vocable/4
    deleteVocable(id: number): Observable<Vocable> {
        let endpoint = this.getVocableUrl() + '/' + id;
        return this.http
            .delete<Vocable>(endpoint, { headers: this.getHeaders() }).pipe(
                catchError(this.handleError));
    }

    // POST api/vocable/{id}/audio
    createVocableAudio(id: number, audio: File): Observable<string> {
        let endpoint = this.getVocableUrl() + '/' + id + '/audio';
        let type = audio.type;
        if (audio.type == 'audio/mp3') {
            type = 'audio/mpeg';
        }
        return this.http
            .post<string>(endpoint, audio, { headers: this.getUploadHeaders(type) }).pipe(
                catchError(this.handleError));
    }

    createVocableImage(id: number, image: File): Observable<string> {
        let endpoint = this.getVocableUrl() + '/' + id + '/image';
        let type = image.type;
        return this.http
        .post<string>(endpoint, image, { headers: this.getUploadHeaders(type) }).pipe(
            catchError(this.handleError));
    }

    // DELETE api/vocable/{id}/audio
    deleteVocableAudio(id: number): Observable<any> {
        let endpoint = this.getVocableUrl() + '/' + id + '/audio';
        return this.http
            .delete(endpoint, { headers: this.getHeaders() }).pipe(
                catchError(this.handleError));
    }

    deleteVocableImage(id: number): Observable<any> {
        let endpoint = this.getVocableUrl() + '/' + id + '/image';
        return this.http
            .delete(endpoint, { headers: this.getHeaders() }).pipe(
                catchError(this.handleError));
    }

    createNew(): Vocable {
        return Vocable.create();
    }
}