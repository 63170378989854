﻿export class Book {
    id: number;
    name: string;
    secondaryName: string;
    description: string;
    imagePath: string;
    secondaryImagePath: string;
    isActive: boolean;
    updatedOn?: Date;
    languageList: string[] = [];

    public static create(): Book {
      const r = new Book();
        return r;
    }
}
