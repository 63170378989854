import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { toast, MaterializeDirective } from 'angular2-materialize';

import { Vocable } from '../model/vocable';
import { VocableInfo } from '../model/vocable-info';
import { Book } from '../model/book';
import { BookInfo } from '../model/book-info';
import { Chapter } from '../model/chapter';
import { Category } from '../model/category';
import { Language } from '../model/language';
import { Translation } from '../model/translation';

import { VocableService } from '../service/vocable.service';
import { BookService } from '../service/book.service';
import { ChapterService } from  '../service/chapter.service';
import { CategoryService } from '../service/category.service';
import { LanguageService } from '../service/language.service';
import { TranslationService } from '../service/translation.service';

@Component({
  selector: 'vocables',
  templateUrl: './vocables.component.html',
  styleUrls: ['./vocables.component.css']
})
export class VocablesComponent implements OnInit {

    title = 'Vokabeln';
    isError: boolean = false;

    books: Book[];
    isBookListLoading: boolean = false;
    selectedBook: number;

    chapters: Chapter[];
    isChapterListLoading: boolean = false;
    selectedChapter: number;

    categories: Category[];
    selectedCategory: number;

    isVocableEditMode: boolean = true;
    isTranslationEditMode: boolean = false;

    constructor(private router: Router,
        private vocableService: VocableService,
        private bookService: BookService,
        private chapterService: ChapterService,
        private categoryService: CategoryService,
        private languageService: LanguageService,
        private translationService: TranslationService
    ) { }

    ngOnInit() {
        this.refreshBookList();
    }

    refreshBookList() {
        this.isBookListLoading = true;
        this.bookService.getBooks().subscribe((books) => {
            this.isBookListLoading = false;
            this.books = books;
        }, (error) => {
            this.isBookListLoading = false;
            this.isError = true;
            toast('Bücher konnten nicht geladen werden', 5000);
        });
    }

    selectBook($event) {
        this.selectedBook = $event.target.value;
        this.selectedChapter = null;
        this.selectedCategory = null;
        if (this.selectedBook) {
            this.loadChapterList(this.selectedBook);
        }
    }

    loadChapterList(id: number) {
        this.isChapterListLoading = true;
        this.chapterService.getChaptersByBook(id).subscribe((chapters) => {
            this.isChapterListLoading = false;
            this.chapters = chapters;
        }, (error) => {
            this.isChapterListLoading = false;
            toast('Kapitel konnten nicht geladen werden', 5000);
        });
    }

    selectChapter($event) {
        this.selectedChapter = $event.target.value;
        this.selectedCategory = null;
        if (this.selectedChapter) {
            this.loadCategoryList(this.selectedChapter);
        }
    }

    loadCategoryList(id: number) {
        this.categoryService.getCategoryByChapter(id).subscribe((categries) => {
            this.categories = categries;
        }, (error) => {
            toast('Kategorien konnten nicht geladen werden', 5000);
        });
    }

    selectCategory($event) {
        this.selectedCategory = $event.target.value;
        this.isVocableEditMode = true;
        this.isTranslationEditMode = false;
    }

    changeEditMode(change: boolean) {
        if (change) {
            this.isVocableEditMode = false;
            this.isTranslationEditMode = true;
        } else {
            this.isVocableEditMode = true;
            this.isTranslationEditMode = false;
        }
    }


}