import { Component, Input, AfterViewChecked, OnChanges } from '@angular/core';
import { toast, MaterializeDirective } from 'angular2-materialize';

import { Vocable } from '../../model/vocable';

import { VocableService } from '../../service/vocable.service';
import { CategoryService } from '../../service/category.service';

@Component({
  selector: 'app-vocables-edit',
  templateUrl: './vocables-edit.component.html',
  styleUrls: ['./vocables-edit.component.css']
})
export class VocablesEditComponent implements AfterViewChecked, OnChanges {

  @Input('categoryId') selectedCategory: number;

  vocables: Vocable[];
  isVocableListLoading: boolean = false;

  newVocable: Vocable = this.vocableService.createNew();
  vocableAddMode: boolean = false;

  vocableSaved: boolean;

  audioFilesToUpload: File[] = [];
  imageFilesToUpload: File[] = [];
  fileToImport: File;

  vocableToDelete: Vocable;

  vocableImportMode: boolean = false;

  constructor(private vocableService: VocableService,
              private categoryService: CategoryService) { }

  ngOnChanges() {
    this.loadVocableList(this.selectedCategory);
  }

  ngAfterViewChecked() {
    (<any>$('.modal-trigger')).leanModal();
  }

  loadVocableList(id: number) {
    this.isVocableListLoading = true;
    this.vocableService.getVocableByCategory(id).subscribe((vocables) => {
      this.isVocableListLoading = false;
      this.vocables = vocables;
      this.vocables.forEach((vocable) => {
        this.convertSourceText(vocable, true, false);
      });
    }, (error) => {
      this.isVocableListLoading = false;
      toast('Vokabeln konnten nicht geladen werden', 5000);
    });
  }

  convertSourceText(vocable: Vocable, forEdit: boolean, forSave: boolean) {
    let sourceTextFast: string = vocable.sourceTextFast;
    let sourceTextIntensive: string = vocable.sourceTextIntensive;
    if (forEdit) {
      // prepare for edit
      // replace brackets with star char
      let replacer = /(\[\s|\s\])/g;
      let replacerSentenseStart = /(\s\[\s)/;
      let replacerSentenseChar = /(\s[.!?,:;])/g;
      let replacerSentenseEnd = /(\s\]\s)/g;
      // replace sentence start bracket
      if (sourceTextFast.match(replacerSentenseStart) && sourceTextFast.indexOf(' [ ') == 0) {
        sourceTextFast = sourceTextFast.replace(replacerSentenseStart, '\*');
      }
      if (sourceTextIntensive.match(replacerSentenseStart) && sourceTextIntensive.indexOf(' [ ') == 0) {
        sourceTextIntensive = sourceTextIntensive.replace(replacerSentenseStart, '\*');
      }
      // replace last bracket without sentence end char
      // -3 because ' ] ' -> charlength == 3,
      if (sourceTextFast.match(replacerSentenseEnd) && sourceTextFast.lastIndexOf(' ] ') == sourceTextFast.length-3) {
        sourceTextFast = sourceTextFast.replace(replacerSentenseEnd, '\*');
      }
      if (sourceTextIntensive.match(replacerSentenseEnd) && sourceTextIntensive.lastIndexOf(' ] ') == sourceTextIntensive.length-3) {
        sourceTextIntensive = sourceTextIntensive.replace(replacerSentenseEnd, '\*');
      }
      // replace other brackets
      if (sourceTextFast.match(replacer)) {
        sourceTextFast = sourceTextFast.replace(replacer, '\*');
      }
      if (sourceTextIntensive.match(replacer)) {
        sourceTextIntensive = sourceTextIntensive.replace(replacer, '\*');
      }
      // replace sentence end chars like .,:;!?
      if (sourceTextFast.match(replacerSentenseChar)) {
        sourceTextFast = sourceTextFast.replace(replacerSentenseChar,
            (res) => {return res = res.replace(/\s/, '');});
      }
      if (sourceTextIntensive.match(replacerSentenseChar)) {
        sourceTextIntensive = sourceTextIntensive.replace(replacerSentenseChar,
            (res) => {return res = res.replace(/\s/, '');});
      }
    }
    if (forSave) {
      // prepare for save
      // replace star char with brackets
      let replacer = /(\*[.!?,:;]|\*)/g;
      if (sourceTextFast.match(replacer)) {
        let matched = sourceTextFast.match(replacer);
        let i = 1;
        while (i <= matched.length) {
          // check if even or odd and replace star with right or left bracket
          if(i % 2 === 0) {
            if (sourceTextFast.lastIndexOf('*') == sourceTextFast.length-1) {
              sourceTextFast = sourceTextFast.replace(/\*/, ' ] ');
            } else {
              sourceTextFast = sourceTextFast.replace(/\*/, ' ]');
            }
          } else {
            if (sourceTextFast.indexOf('*') == 0) {
              sourceTextFast = sourceTextFast.replace(/\*/, ' [ ');
            } else {
              sourceTextFast = sourceTextFast.replace(/\*/, '[ ');
            }
          }
          i+=1;
        }
      }
      if (sourceTextIntensive.match(replacer)) {
        let matched = sourceTextIntensive.match(replacer);
        let i = 1;
        while (i <= matched.length) {
          if(i % 2 === 0) {
            if (sourceTextIntensive.lastIndexOf('*') == sourceTextIntensive.length-1) {
              sourceTextIntensive = sourceTextIntensive.replace(/\*/, ' ] ');
            } else {
              sourceTextIntensive = sourceTextIntensive.replace(/\*/, ' ]');
            }
          } else {
            if (sourceTextIntensive.indexOf('*') == 0) {
              sourceTextIntensive = sourceTextIntensive.replace(/\*/, ' [ ');
            } else {
              sourceTextIntensive = sourceTextIntensive.replace(/\*/, '[ ');
            }
          }
          i+=1;
        }
      }
      // add space between bracket and sentence end char
      let replacerEnd = /(\s\][.!?,:;])/g;
      if (sourceTextFast.match(replacerEnd)) {
        sourceTextFast = sourceTextFast.replace(replacerEnd,
            (res) => {return res = res.replace(/\]/, '] ');});
      }
      if (sourceTextIntensive.match(replacerEnd)) {
        sourceTextIntensive = sourceTextIntensive.replace(replacerEnd,
            (res) => {return res = res.replace(/\]/, '] ');});
      }
    }
    vocable.sourceTextFast = sourceTextFast;
    vocable.sourceTextIntensive = sourceTextIntensive;
    return vocable;
  }

  saveVocable(vocable: Vocable, showToast: boolean = true) {
    vocable.categoryId = this.selectedCategory;
    if (vocable.id) {
      this.convertSourceText(vocable, false, true);
      this.vocableService.updateVocable(vocable).subscribe((updatedVocable) => {
        this.convertSourceText(vocable, true, false);
        if (this.audioFilesToUpload[vocable.id]) {
          this.uploadVocableAudio(vocable.id, showToast);
        };
        if (this.imageFilesToUpload[vocable.id]){
          this.uploadVocabelImage(vocable.id, showToast);
        };
        this.vocableSaved = true;
        if (showToast) {
          toast('Die Vokabel wurde erfolgreich aktualisiert', 5000);
        }
      }, (error) => {
        this.vocableSaved = false;
        if (showToast) {
          toast('Die Vokabel konnte nicht aktualisiert werden', 5000);
        }
      });
    } else {
      this.convertSourceText(vocable, false, true);
      this.vocableService.createVocable(vocable).subscribe((createdVocable) => {
        this.loadVocableList(this.selectedCategory);
        this.handleVocableAddMode(this.vocableAddMode);
        this.vocableSaved = true;
        if (showToast) {
          toast('Neue Vokabel wurde erfolgreich angelegt', 5000);
        }
      }, (error) => {
        this.vocableSaved = false;
        if (showToast) {
          toast('Neue Vokabel konnte nicht angelegt werden', 5000);
        }
      });
    }
  }

  saveAllVocable() {
    this.vocables.forEach((vocable) => {
      this.saveVocable(vocable, false);
    });
    if (this.vocableSaved) {
      toast('Die Vokabeln wurden erfolgreich aktualisiert.', 5000);
    }
  }

  handleVocableAddMode(vocableAddMode: boolean) {
    vocableAddMode ? this.vocableAddMode = false : this.vocableAddMode = true;
  }

  addNewVocable() {
    this.handleVocableAddMode(this.vocableAddMode);
  }

  deleteConfirmation(vocable: Vocable) {
    this.vocableToDelete = vocable;
  }

  deleteVocable(id: number) {
    this.vocableService.deleteVocable(id).subscribe(() => {
      this.loadVocableList(this.selectedCategory);
      toast('Die Vokabel wurde erfolgreich gelöscht!', 5000);
    }, (error) => {
      toast('Die Vokabel konnte nicht gelöscht werden!', 5000);
    });
  }

  // upload book cover
  handleAudioFileInput(files: FileList, id: number) {
    this.audioFilesToUpload[id] = files.item(0);
  }

  handleImageFileInput(files: FileList, id: number) {
    this.imageFilesToUpload[id] = files.item(0);
  }

  uploadVocableAudio(id: number, showToast: boolean = true) {
    this.vocableService.createVocableAudio(id, this.audioFilesToUpload[id]).subscribe((audio) => {
      this.audioFilesToUpload[id] = null;
      if (showToast) {
        toast('Die Audiodatei wurde erfolgreich hochgeladen.', 5000);
      }
    }, (error) => {
      if (showToast) {
        toast('Die Audiodatei konnte nicht hochgeladen werden.', 5000);
      }
    });
  }

  uploadVocabelImage(id: number, showToast: boolean = true) {
    this.vocableService.createVocableImage(id, this.imageFilesToUpload[id]).subscribe((image) => {
      this.imageFilesToUpload[id] = null;
      if (showToast) {
        toast('Die Bilddatei wurde erfolgreich hochgeladen.', 5000);
      }
    }, (error) => {
      if (showToast) {
        toast('Die Bilddatei konnte nicht hochgeladen werden.', 5000);
      }
    });
  }
 
  // Currently not in use anymore, the backend handles this when a vocabel gets deleted. 
  deleteVocableAudio(id: number, showToast: boolean = true) {
    this.vocableService.deleteVocableAudio(id).subscribe(() => {
      if (showToast) {
        toast('Die Audiodatei wurde erfolgreich gelöscht!', 5000);
      }
    }, (error) => {
      if (showToast) {
        toast('Die Audiodatei konnte nicht gelöscht werden!', 5000);
      }
    });
  }

  // Currently not in use anymore, the backend handles this when a vocabel gets deleted.
  deleteVocableImage(id: number, showToast: boolean = true) {
    this.vocableService.deleteVocableImage(id).subscribe(() => {
      if (showToast) {
        toast('Die Bilddatei wurde erfolgreich gelöscht!', 5000);
      }
    }, (error) => {
      if (showToast) {
        toast('Die Bilddatei konnte nicht gelöscht werden!', 5000);
      }
    });
  }

  handleVocableImportMode(vocableImportMode: boolean) {
    vocableImportMode ? this.vocableImportMode = false : this.vocableImportMode = true;
  }

  handleImportFileInput(files: FileList) {
    this.fileToImport = files.item(0);
  }

  importVocables() {
    if (this.fileToImport) {
      this.isVocableListLoading = true;
      this.categoryService.importVocable(this.selectedCategory, this.fileToImport).subscribe((csv) => {
        this.handleVocableImportMode(this.vocableImportMode);
        this.loadVocableList(this.selectedCategory);
        this.fileToImport = null;
        toast('Vokabeln wurden erfolgreich importiert', 5000);
      }, (error) => {
        this.handleVocableImportMode(this.vocableImportMode);
        this.fileToImport = null;
        toast('Import von Vokabeln ist Fehlgeschlagen', 5000);
      });
    }
  }

}
