
import { throwError as observableThrowError, BehaviorSubject, Observable } from 'rxjs';

import { map, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response, Headers, URLSearchParams } from '@angular/http';
import { Token } from './authentication'
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BuildInfo } from 'app/model/build-info';

const currentUserKey = 'currentUser';

@Injectable()
export class AuthenticationService {

  redirectUrl: string;

  private userToken: BehaviorSubject<Token>;
  private accessToken: string;

  constructor(private http: HttpClient) {
    this.userToken = new BehaviorSubject<Token>(null);

    this.userToken.subscribe(data => {
      if (data && data.access_token) {
        this.accessToken = data.access_token;
      } else {
        this.accessToken = null;
      }
    })

    const tokenString = sessionStorage.getItem(currentUserKey);
    if (tokenString) {
      const token = JSON.parse(tokenString) as Token;
      this.userToken.next(token);
    }
  }

  get userName(): Observable<string> {
    return this.userToken.pipe(map(data => {
      if (data && data.userName) {
        return data.userName;
      }
      return '';
    }))
  }

  private getAccessToken(): string {
    const tokenString = sessionStorage.getItem(currentUserKey);
    if (tokenString) {
      const token = JSON.parse(tokenString) as Token;
      return token.access_token;
    }

    return null;
  }

  getAuthHeaderValue(): string {
    const accessToken = this.getAccessToken();
    if (accessToken) {
      return `Bearer ${accessToken}`
    }
    return null;
  }

  login(username: string, password: string): Observable<Token> {
    let params = new HttpParams();
    params = params.append('username', username);
    params = params.append('password', password);
    params = params.append('grant_type', 'password');
    const body = params.toString()

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const url = environment.webAppBaseUrl + '/api/token';

    return this.http.post<Token>(url, body, { headers, params })
    .pipe(
      catchError(this.handleError),
      tap(data => {
        if (data) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          sessionStorage.setItem(currentUserKey, JSON.stringify(data));
          this.userToken.next(data);
        } else {
          sessionStorage.removeItem(currentUserKey);
          this.userToken.next(null);
          this.handleError('unable to login');
        }
      })
    );
  }

  getBuildInfo(): Observable<BuildInfo> {
    return this.http.get<BuildInfo>(environment.webAppBaseUrl + '/api/account/buildinfo')
    .pipe(catchError(e => this.handleError(e)));
  }

  private handleError(error: Response | any) {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || {};
      if (body.error) {
        errMsg = body.error;
      } else {
        errMsg = body.ExceptionMessage || body.Message;
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }

  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem(currentUserKey);
    this.userToken.next(null);
  }


  isLoggedIn(): Observable<boolean> {
    return this.userToken.pipe(map((t: Token) => t != null));
  }

  isUserTokenValid(): boolean {
    const tokenString = sessionStorage.getItem(currentUserKey);
    if (!tokenString) {
      return false;
    }

    const token = JSON.parse(tokenString) as Token;
    // TODO: calculate expiration
    return true;
  }

  isUserTokenAvailable(): boolean {
    const tokenString = sessionStorage.getItem(currentUserKey);
    return !tokenString;
  }
}
