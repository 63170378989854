import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'vokabel-app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

  title = 'Übersicht';
    
}