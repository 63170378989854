import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Component({
    templateUrl: './login.component.html'
})

export class LoginComponent {
    username: string;
    password: string;
    
    error: string;

    isLoggedIn : boolean;

    constructor(private authService: AuthenticationService, private router: Router) {
        authService.isLoggedIn().subscribe(v => this.isLoggedIn = v);
    }

    onLoginSubmit() {
        this.error = null;
        this.authService.login(this.username, this.password)
            .subscribe(data => {
                if (this.authService.isUserTokenValid()) {
                    // Get the redirect URL from our auth service
                    // If no redirect has been set, use the default
                    let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';
                    // Redirect the user
                    this.router.navigate([redirect]);
                }
            }, err => {
                this.error = 'Benutzer und Kennwort stimmen nicht überein'
            });
    }

    onLogoutSubmit(){
        this.authService.logout();
        this.router.navigate(["/login"]);
    }
}
