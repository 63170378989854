import { Component, Input } from '@angular/core';
import {FormGroup, FormBuilder, FormArray, FormControl} from '@angular/forms';
import { toast, MaterializeDirective } from 'angular2-materialize';

import { Exercise, ChoiceOptionItem } from '../../model/exercise';

import { ExerciseService } from '../../service/exercise.service';

@Component({
  selector: 'app-fill-the-gap',
  templateUrl: './fill-the-gap.component.html',
  styleUrls: ['./fill-the-gap.component.css']
})
export class FillTheGapComponent {

  @Input() exerciseToEdit: Exercise;
  @Input() selectedCategory: number;

  fillTheGapForm: FormGroup;

  correctSentence: string = '';

  isEditExerciseLoading: boolean = false;

  constructor(private exerciseService: ExerciseService,
              private _fb: FormBuilder
  ) {
    this.createForm();
  }

  createForm() {
    this.fillTheGapForm = this._fb.group({
      id: [],
      exerciseType: [],
      label: [],
      categoryId: [],
      choiceOptions: this._fb.group({
        label: [],
        items: this._fb.array([]),
        pattern: [],
        correctSentence: []
      }),
      pairOptions: [null],
      sortOptions: [null]
    });
  }

  ngOnChanges() {
    if(this.exerciseToEdit.choiceOptions.pattern && this.exerciseToEdit.choiceOptions.items) {
      this.correctSentence = this.getSentenceSolution(this.exerciseToEdit.choiceOptions.pattern,
          this.exerciseToEdit.choiceOptions.items);
    }
    this.fillTheGapForm.reset({
      label: this.exerciseToEdit.label,
      choiceOptions: {
        label: this.exerciseToEdit.choiceOptions.label,
        pattern: this.exerciseToEdit.choiceOptions.pattern,
        correctSentence: this.correctSentence
      }
    });
    this.setOptionItems(this.exerciseToEdit.choiceOptions.items);
  }

  onSubmit() {
    if(this.disableCheckbox) {
      this.exerciseToEdit = this.prepareSaveForm();
      this.exerciseService.updateExercise(this.exerciseToEdit).subscribe((exercise) => {
        toast('Änderungen wurden erfolgreich gespeichert', 5000);
        this.refreshEditData(this.exerciseToEdit.id);
      }, (error) => {
        toast('Änderungen konnten nicht gespeichert werden', 5000);
      });
    } else {
      toast('Es muss eine richtige Lösung geben', 5000);
    }

  }

  refreshEditData(id: number) {
    this.isEditExerciseLoading = true;
    this.exerciseService.getExercise(id).subscribe((exercise) => {
      this.isEditExerciseLoading = false;
      this.exerciseToEdit = exercise;
    }, (error) => {
      this.isEditExerciseLoading = false;
      toast('Beim Aktualisieren ist ein Fehler aufgetretten', 5000);
    });
  }

  onReset() {
    this.ngOnChanges();
  }

  get disableCheckbox() {
    const items = this.items.value;
    const correctAnswer = items.find((item) => {
      return item.isCorrectAnswer === true;
    });
    if(correctAnswer) {
      return true;
    }
  }

  getSentenceSolution(pattern: string, items: ChoiceOptionItem[], forPreview: boolean = false, savePattern: boolean = false) {
    const correctAnswer = items.find((item) => {
      return item.isCorrectAnswer === true;
    });
    let replacer = '***';
    let solution = pattern.replace(/%SOLUTION%/, replacer);
    if(forPreview) {
      replacer = '___';
      if(correctAnswer) {
        replacer = correctAnswer.text;
      }
      solution = pattern.replace(/\*\*\*/, replacer);
    }
    if(savePattern) {
      solution = pattern.replace(replacer, '%SOLUTION%');
    }
    return solution;
  }

  get previewCorrectSentence() {
    const sentence = this.fillTheGapForm.get('choiceOptions').get('correctSentence').value;
    return this.getSentenceSolution(sentence, this.items.value, true);
  }

  // fill FormArray items with content
  setOptionItems(items: ChoiceOptionItem[]) {
    const options = items.map(item => this._fb.group(item));
    const optionsArray = this._fb.array(options);
    this.fillTheGapForm.setControl('items', optionsArray);
  }

  get items(): FormArray {
    return this.fillTheGapForm.get('items') as FormArray;
  }

  addNewOption() {
    const item: ChoiceOptionItem = {
      orderNumber: this.items.length+1,
      text: '',
      isCorrectAnswer: false
    };
    this.items.push(this._fb.group(item));
  }

  removeOptionItem(i: number) {
    this.items.removeAt(i);
  }

  // set random initialOrderNumber
  randomizeSortItems() {
    const items = this.fillTheGapForm.get('items').value;
    const array = [];
    const randomizedItems: any[] = [];
    while(array.length < items.length) {
      const random = Math.ceil(Math.random()*items.length);
      if(array.indexOf(random) > -1) continue;
      array[array.length] = random;
      const item: ChoiceOptionItem = {
        orderNumber: random,
        text: items[array.length-1].text,
        isCorrectAnswer: items[array.length-1].isCorrectAnswer
      };
      randomizedItems.push(item);
    }
    this.setOptionItems(randomizedItems);
  }

  prepareSaveForm(): Exercise {
    // get full value from Form
    const formModel = this.fillTheGapForm.value;

    // get all ChoiceOptionItems from Form
    const deepItemsCopy: ChoiceOptionItem[] = formModel.items.map(
        (item: ChoiceOptionItem) => Object.assign({}, item)
    );

    const patternToSave = this.getSentenceSolution(formModel.choiceOptions.correctSentence,
        deepItemsCopy, false, true);

    // bring Form value in right order
    const saveForm: Exercise = {
      id: this.exerciseToEdit.id,
      exerciseType: 'FillTheGap',
      label: formModel.label,
      categoryId: this.selectedCategory,
      choiceOptions: {
        label: formModel.choiceOptions.label,
        items: deepItemsCopy,
        pattern: patternToSave
      },
      pairOptions: null,
      sortOptions: null
    };

    return saveForm;
  }

}
