import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { toast, MaterializeDirective } from 'angular2-materialize';

import { Exercise, ChoiceOptionItem } from '../../model/exercise';

import { ExerciseService } from '../../service/exercise.service';

@Component({
  selector: 'app-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['./multiple-choice.component.css']
})
export class MultipleChoiceComponent implements OnChanges {

  @Input() exerciseToEdit: Exercise;
  @Input() selectedCategory: number;

  multipleChoiceForm: FormGroup;

  isEditExerciseLoading: boolean = false;

  constructor(private exerciseService: ExerciseService,
              private _fb: FormBuilder
  ) {
    this.createForm();
  }

  createForm() {
    this.multipleChoiceForm = this._fb.group({
      id: [],
      exerciseType: [],
      label: [],
      categoryId: [],
      choiceOptions: this._fb.group({
        label: [],
        items: this._fb.array([]),
        pattern: []
      }),
      pairOptions: [null],
      sortOptions: [null]
    });
  }

  ngOnChanges() {
    this.multipleChoiceForm.reset({
      label: this.exerciseToEdit.label,
      choiceOptions: {
        label: this.exerciseToEdit.choiceOptions.label,
        pattern: this.exerciseToEdit.choiceOptions.pattern
      }
    });
    this.setOptionItems(this.exerciseToEdit.choiceOptions.items);
  }

  onSubmit() {
    this.exerciseToEdit = this.prepareSaveForm();
    this.exerciseService.updateExercise(this.exerciseToEdit).subscribe((exercise) => {
      toast('Änderungen wurden erfolgreich gespeichert', 5000);
      this.refreshEditData(this.exerciseToEdit.id);
    }, (error) => {
      toast('Änderungen konnten nicht gespeichert werden', 5000);
    });
  }

  refreshEditData(id: number) {
    this.isEditExerciseLoading = true;
    this.exerciseService.getExercise(id).subscribe((exercise) => {
      this.isEditExerciseLoading = false;
      this.exerciseToEdit = exercise;
    }, (error) => {
      this.isEditExerciseLoading = false;
      toast('Beim Aktualisieren ist ein Fehler aufgetretten', 5000);
    });
  }

  onReset() {
    this.ngOnChanges();
  }

  // fill FormArray items with conteng
  setOptionItems(items: ChoiceOptionItem[]) {
    const options = items.map(item => this._fb.group(item));
    const optionsArray = this._fb.array(options);
    this.multipleChoiceForm.setControl('items', optionsArray);
  }

  get items(): FormArray {
    return this.multipleChoiceForm.get('items') as FormArray;
  }

  addNewOption() {
    const item: ChoiceOptionItem = {
      orderNumber: this.items.length+1,
      text: '',
      isCorrectAnswer: false
    };
    this.items.push(this._fb.group(item));
  }

  removeOptionItem(i: number) {
    this.items.removeAt(i);
  }

  // set random initialOrderNumber
  randomizeSortItems() {
    const items = this.multipleChoiceForm.get('items').value;
    const array = [];
    const randomizedItems: any[] = [];
    while(array.length < items.length) {
      const random = Math.ceil(Math.random()*items.length);
      if(array.indexOf(random) > -1) continue;
      array[array.length] = random;
      const item: ChoiceOptionItem = {
        orderNumber: random,
        text: items[array.length-1].text,
        isCorrectAnswer: items[array.length-1].isCorrectAnswer
      };
      randomizedItems.push(item);
    }
    this.setOptionItems(randomizedItems);
  }

  prepareSaveForm(): Exercise {
    // get full value from Form
    const formModel = this.multipleChoiceForm.value;

    // get all ChoiceOptionItems from Form
    const deepItemsCopy: ChoiceOptionItem[] = formModel.items.map(
        (item: ChoiceOptionItem) => Object.assign({}, item)
    );

    // bring Form value in right order
    const saveForm: Exercise = {
      id: this.exerciseToEdit.id,
      exerciseType: 'MultipleChoice',
      label: formModel.label,
      categoryId: this.selectedCategory,
      choiceOptions: {
        label: formModel.choiceOptions.label,
        items: deepItemsCopy,
        pattern: formModel.choiceOptions.pattern
      },
      pairOptions: null,
      sortOptions: null
    }

    return saveForm;
  }

}
