import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pair-option',
  templateUrl: './pair-option.component.html',
  styles: []
})
export class PairOptionComponent implements OnChanges {

  @Input() choiceItem: FormGroup;
  @Input() index: number;
  @Input() position: boolean;
  @Input() separator: boolean;

  hide: boolean = false;

  constructor() { }

  ngOnChanges() {
    // check if label and labelOrderNumber empty, then set hide to true
    // if hide true, then load single option form
    if(this.choiceItem.value.labelOrderNumber==null && this.choiceItem.value.label==null) {
      this.hide = true;
    }
  }

}
