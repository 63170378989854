import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sort-option',
  templateUrl: './sort-option.component.html',
  styles: []
})
export class SortOptionComponent {

  @Input() sortItem: FormGroup;
  @Input() index: number;
  @Input() length: number;

  constructor() { }

}
