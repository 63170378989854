import { Component, OnInit, Input } from '@angular/core';
import { toast, MaterializeDirective } from 'angular2-materialize';

import { Vocable } from '../../model/vocable';
import { Language } from '../../model/language';
import { Translation } from '../../model/translation';

import { VocableService } from '../../service/vocable.service';
import { LanguageService } from '../../service/language.service';
import { TranslationService } from '../../service/translation.service';

@Component({
  selector: 'app-translations-edit',
  templateUrl: './translations-edit.component.html',
  styleUrls: ['./translations-edit.component.css']
})
export class TranslationsEditComponent implements OnInit {

  @Input('categoryId') selectedCategory: number;

  isVocableListLoading: boolean = false;
  vocableInfo: Vocable[];

  isLanguageListLoading: boolean = false;
  languages: Language[];
  isRtlLanguage: boolean = false;
  selectedLanguage: Language = null;

  isTranslationLoading: boolean;
  isTranslationSaved: boolean[] = [];
  translations: Translation[];

  constructor(private vocableService: VocableService,
              private languageService: LanguageService,
              private translationService: TranslationService
  ) { }

  ngOnInit() {
    this.loadVocableList(this.selectedCategory);
    this.loadLanguageList();
  }

  loadVocableList(id: number) {
    this.isVocableListLoading = true;
    this.vocableService.getVocableByCategory(id).subscribe((vocableInfo) => {
      this.isVocableListLoading = false;
      this.vocableInfo = vocableInfo;
    }, (error) => {
      this.isVocableListLoading = false;
      toast('Vokabeln konnten nicht geladen werden', 5000);
    });
  }

  loadLanguageList() {
    this.isLanguageListLoading = true;
    this.languageService.getLanguages().subscribe((languages) => {
      this.isLanguageListLoading = false;
      this.languages = languages;
    }, (error) => {
      this.isLanguageListLoading = false;
      toast('List mit verfügbaren Sprachen konnte nicht geladen werden', 5000);
    });
  }

  onSelectLanguage() {
    this.loadTranslation();
  }

  loadTranslation() {
    this.isTranslationLoading = true;
    this.translationService.getTranslationByCategory(this.selectedCategory, this.selectedLanguage.languageCode)
        .subscribe((translation) => {
      this.isTranslationLoading = false;
      // if empty object, create new translation
      for(var i=0; i < translation.length; i++) {
        if(!translation[i]) {
          translation[i] = new Translation();
          translation[i].languageCode = this.selectedLanguage.languageCode;
          translation[i].translation = '';
          translation[i].advice = '';
        }
      }
      this.translations = translation;
    }, (error) => {
      this.isTranslationLoading = false;
      toast('Übersetzung konnte nicht geladen werden', 5000);
    });
  }

  onSaveExistingTranslation(translation: Translation, id: number, showToast: boolean = true) {
    if (translation.id) {
      this.translationService.updateTranslation(translation).subscribe((result) => {
        this.isTranslationSaved.push(true);
        if (showToast) {
          toast('Die Übersetzung für ' + this.selectedLanguage.longName + ' wurde geändert!', 5000);
        }
      }, (error) => {
        this.isTranslationSaved.push(false);
      });
    } else {
      console.log('Keine Übersetzung für aktuelle Sprache gefunden. Neue erstellt.');
      translation.id = id;
      this.translationService.createTranslation(translation)
          .subscribe((result) => {
            this.isTranslationSaved.push(true);
            if (showToast) {
              toast('Eine neue Übersetzung für ' + this.selectedLanguage.longName + ' wurde angelegt!', 5000);
            }
          }, (error) => {
            this.isTranslationSaved.push(false);
          });
    }
  }

  onSaveAllTranslations() {
    this.translations.forEach((translation, index) => {
      this.onSaveExistingTranslation(translation, this.vocableInfo[index].id, false);
    });
    if (this.isTranslationSaved) {
      toast('Die Übersetzungen für ' + this.selectedLanguage.longName + ' wurden erfolgreich gespeichert', 5000);
    } else {
      toast('Die Übersetzungen für ' + this.selectedLanguage.longName + ' konnten nicht gespeichert werden', 5000);
    }
  }

}
