import { Component } from '@angular/core';
import {AuthenticationService} from "./auth/authentication.service";
import { BuildInfo } from './model/build-info';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'vokabel-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  isLoggedIn: boolean = false;
  buildInfo: BuildInfo;

  constructor(protected authService: AuthenticationService) {
    authService.isLoggedIn().subscribe(v => this.isLoggedIn = v);
    authService.getBuildInfo().pipe(catchError(e => of(null))).subscribe(info => this.buildInfo = info);
  }
}
