﻿import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth/authguard.service';

import { CategoryComponent } from './category/category.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BookComponent } from './book/book.component';
import { BooksComponent } from './books/books.component';
import { VocablesComponent } from './vocables/vocables.component';
import { ExerciseComponent } from './exercise/exercise.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { LoginComponent } from './auth/login.component';

const appRoutes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    { path: 'login', component: LoginComponent },
    {
        path: 'books',
        canActivate: [AuthGuard],
        component: BooksComponent
    },
    {
        path: 'detail/:id',
        canActivate: [AuthGuard],
        component: BookComponent
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: DashboardComponent
    },
    {
        path: 'dashboard/:id',
        canActivate: [AuthGuard],
        component: DashboardComponent
    },
    {
        path: 'category/:id',
        canActivate: [AuthGuard],
        component: CategoryComponent
    },
    {
        path: 'vocables',
        canActivate: [AuthGuard],
        component: VocablesComponent
    },
    {
        path: 'exercises',
        canActivate: [AuthGuard],
        component: ExerciseComponent
    },
    {
        path: 'notifications',
        canActivate: [AuthGuard],
        component: NotificationsComponent
    }
];

export const AppRouting: ModuleWithProviders = RouterModule.forRoot(appRoutes);
