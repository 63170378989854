import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-gap-item',
  templateUrl: './gap-item.component.html',
  styles: []
})
export class GapItemComponent {

  @Input() choiceItem: FormGroup;
  @Input() index: number;
  @Input() disabled: boolean;

  constructor() { }

}