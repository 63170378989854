
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from '../shared/basedata.service';
import { AuthenticationService } from '../auth/authentication.service';

import { Chapter } from "../model/chapter";
import { ChapterInfo } from "../model/chapter-info";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ChapterService extends BaseDataService {

    private chapterUrl = '/api/chapter';

    constructor(private http: HttpClient,
        protected authService: AuthenticationService) {
        super(authService)
    }

    public getChapterUrl() {
        return super.getFullUrl(this.chapterUrl);
    }

    // GET api/chapter/chapterbybook/5
    getChaptersByBook(id: number): Observable<Chapter[]> {
        let endpoint = this.getChapterUrl() + '/bybook/' + id;
        return this.http
            .get<Chapter[]>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // GET api/chapter/chapterbyid/5
    getChapter(id: number): Observable<Chapter> {
        let endpoint = this.getChapterUrl() + '/' + id;
        return this.http
            .get<Chapter>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // GET api/chapter/info/{id}
    getChapterInfo(id: number): Observable<ChapterInfo> {
        let endpoint = this.getChapterUrl() + '/info/' + id;
        return this.http
            .get<ChapterInfo>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // PUT api/chapter
    updateChapter(chapter: Chapter): Observable<Chapter> {
        return this.http
            .put<Chapter>(this.getChapterUrl(), chapter, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // POST api/chapter
    createChapter(chapter: Chapter): Observable<Chapter> {
        return this.http
            .post<Chapter>(this.getChapterUrl(), chapter, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    // DELETE api/chapter/4
    deleteChapter(id: number): Observable<Chapter> {
        let endpoint = this.getChapterUrl() + '/' + id;
        return this.http
            .delete<Chapter>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    postCover(id: number, image: File): Observable<string> {
        let endpoint = this.getChapterUrl() + '/' + id + '/image';
        return this.http
            .post<string>(endpoint, image, { headers: this.getUploadHeaders(image.type) }).pipe(
            catchError(this.handleError));
    }

    createNew() : Chapter {
        return Chapter.create();
    }
}