import { HttpHeaders } from '@angular/common/http';

export const defaults = {
    getDefaultHeaders() : HttpHeaders {
        let headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'});
        return headers;
    },

    getUploadHeaders(type): HttpHeaders {
        let headers = new HttpHeaders({"Accept": "application/json", "Content-Type": type});
        return headers;
    }
};
