import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { toast } from 'angular2-materialize';

import { Book } from '../model/book';
import { BookInfo } from '../model/book-info';
import { Chapter } from '../model/chapter';
import { Category } from '../model/category';

import { ChapterService } from '../service/chapter.service';
import { CategoryService } from '../service/category.service';
import { BookService } from '../service/book.service';
import { CustomFile } from '../model/custom-file';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.css']
})
export class BookComponent implements OnInit, AfterViewChecked {
    id: number;
    book: Book;
    selectedBook: Book;
    bookInfo: BookInfo;

    chapter: Chapter;
    chapters: Chapter[];
    selectedDeleteChapter: Chapter;
    newChapter: Chapter;
    selectedChapter: Chapter|null;

    categories: Category[];
    selectedDeleteCategory: Category;
    newCategory: Category = this.categoryService.createNew();
    selectedEditCategory: Category|null;

    isLoading = false;
    isChapterLoading = false;
    isChapterInfoLoading = false;

    bookEditMode = false;
    chapterEditMode = false;
    categorySaveMode = false;

    appClient = 0;
    multipleClients = false;
    toggleCover = false;

    filesToUpload: CustomFile[] = [];

    fileToUpload: File|null;
    bookImagePath: string = this.bookService.getBookUrl();
    chapterImagePath: string = this.chapterService.getChapterUrl();
    categoryImagePath: string = this.categoryService.getCategoryUrl();

    constructor(private router: Router,
        private chapterService: ChapterService,
        private categoryService: CategoryService,
        private bookService: BookService,
        private route: ActivatedRoute
    ) { }

    // get detailed book info
    refreshBookInfo() {
        this.isLoading = true;
        this.bookService.getBookInfo(this.id).subscribe((bookInfo) => {
            this.isLoading = false;
            this.bookInfo = bookInfo;
        }, (error) => {
            this.isLoading = false;
            toast('Problem beim Laden von Buchinformationen', 5000);
        });
    }

    // get short book info
    refreshBook() {
        this.isLoading = true;
        this.bookService.getBook(this.id, this.appClient).subscribe((book) => {
            this.book = book;
            if (this.book.secondaryImagePath != null){
                this.multipleClients = true;
                this.toggleCover = false; 
            }
            this.appClient = 0;
        }, (error) => {
            toast('Problem beim Laden der Buchinformationen', 5000);
        });
    }

    get categoriesCount() {
        let categories = 0;
        for (const chapter of this.bookInfo.chapterList) {
            categories += chapter.categoryList.length;
        }
        return categories;
    }

    get vocablesCount() {
        let vocables = 0;
        for (const chapter of this.bookInfo.chapterList) {
            for (const category of chapter.categoryList) {
                vocables += category.vocableList.length;
            }
        }
        return vocables;
    }

    handleBookEditMode(bookEditMode: boolean) {
        bookEditMode ? this.bookEditMode = false : this.bookEditMode = true;
    }

    handleChapterEditMode(chapterEditMode: boolean) {
        chapterEditMode ? this.chapterEditMode = false : this.chapterEditMode = true;
    }

    changeBookActiveStatus() {
        this.book.isActive = !this.book.isActive;
        this.saveBookChanges(true);
        this.isLoading = true;
    }

    onShowAsUpdate() {
        this.book.updatedOn = new Date();
        this.saveBookChanges(true);
    }

    saveBookChanges(bookEditMode: boolean) {
        this.bookService.updateBook(this.book).subscribe((book) => {
            this.refreshBookInfo();
            this.refreshBook();
            this.handleBookEditMode(bookEditMode);
            toast('Das Buch wurde erfolgreich aktualisiert', 5000);
        }, (error) => {
            toast('Beim Aktualisieren der Buchinformationen ist ein Fehler aufgetretten', 5000);
        });
        if (this.filesToUpload.length > 0) {
            this.filesToUpload.forEach(file => {
                this.uploadBookCover(file);
            })
            this.filesToUpload = [];
        }
    }

    saveChapterChanges(chapterEditMode: boolean) {
        this.chapterService.updateChapter(this.selectedChapter).subscribe((chapter) => {
            this.loadChapter(this.selectedChapter.id);
            this.handleChapterEditMode(chapterEditMode);
            toast('Der Kapitel wurde erfolgreich aktualisiert', 5000);
        }, (error) => {
            toast('Beim Aktualisieren des Kapitels ist ein Fehler aufgetretten', 5000);
        });
        if (this.fileToUpload) {
            this.uploadChapterCover();
        }
    }

    // get info for confirmation modal
    setCurrentBook(book: Book) {
        this.selectedBook = book;
    }

    // delete Book
    onDeleteClick(id: number) {
        this.deleteBookCover(id);
        this.bookService.deleteBook(id).subscribe((book) => {
            this.router.navigate(['/books']);
            toast('Das Buch wurde erfolgreich gelöscht', 5000);
        }, (error) => {
            toast('Das Buch konnte nicht gelöscht werden', 5000);
        });
    }

    // handle upload of multiple files at once
    handleFilesInput(files: FileList, appClient: number): void {
        let customFile = new CustomFile();
        customFile.file = files.item(0);
        customFile.appClient = appClient;
        this.filesToUpload.push(customFile);
    }

    // upload book cover
    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }

    uploadBookCover(customFile: CustomFile) {
        this.bookService.postCover(this.book.id, customFile.file , customFile.appClient).subscribe((res) => {
            this.fileToUpload = null;
            toast('Coverbild wurde erfolgreich hochgeladen', 5000);
        }, (error) => {
            toast('Coverbild konnte nicht gespeichert werden', 5000);
        });
    }

    deleteBookCover(id: number) {
        this.bookService.deleteCover(id).subscribe(() => {
            toast('Coverbild wurde gelöscht', 5000);
        }, (error) => {
            toast('Beim Löschen des Coverbildes ist ein Fehler aufgetretten', 5000);
        });
    }

    uploadChapterCover() {
        this.chapterService.postCover(this.selectedChapter.id, this.fileToUpload).subscribe(() => {
            this.refreshBookInfo();
            this.fileToUpload = null;
            toast('Bild wurde erfolgreich hochgeladen', 5000);
        }, (error) => {
            toast('Bild konnte nicht gespeichert werden', 5000);
        });
    }

    // create new chapter
    onCreateNewChapter() {
        this.newChapter.bookId = this.book.id;
        this.newChapter.orderNumber = this.bookInfo.chapterList.length + 1;
        this.newChapter.imagePath = '';
        this.chapterService.createChapter(this.newChapter).subscribe((newChapter) => {
            this.refreshBookInfo();
            toast('Kapitel wurde erfolgreich hinzugefügt', 5000);
        }, (error) => {
            this.refreshBookInfo();
            toast('Beim Hinzufügen des Kapitels ist ein Fehler aufgetretten', 5000);
        });
    }

    // get chapter delete modal information
    onChapterDeleteConfirmationModal(chapter: Chapter) {
        this.selectedDeleteChapter = chapter;
    }

    // delete chapter
    onChapterDeleteClick(id: number) {
        this.chapterService.deleteChapter(id).subscribe((chapter) => {
            this.refreshBookInfo();
            if (this.selectedChapter.id === id) {
                this.selectedChapter = null;
            }
            toast('Kapitel wurde erfolgreich gelöscht', 5000);
        }, (error) => {
            toast('Kapitel konnte nicht gelöscht werden', 5000);
        });
    }

    // get chapter info
    loadChapter(id: number) {
        this.isChapterInfoLoading = true;
        this.chapterService.getChapter(id).subscribe((chapter) => {
            this.isChapterInfoLoading = false;
            this.selectedChapter = chapter;
            this.categoryService.getCategoryByChapter(id).subscribe((categories) => {
                this.categories = categories;
            }, (error) => {
                toast('Beim Laden der Kategorien ist ein Fehler aufgetretten', 5000);
            })
        }, (error) => {
            this.isChapterInfoLoading = false;
            toast('Beim Laden der Kapitelinformation ist ein Fehler aufgetretten', 5000);
        });
    }

    // get category delete modal information
    onCategoryDeleteConfirmationModal(category: Category) {
        this.selectedDeleteCategory = category;
    }

    // delete category
    onCategoryDeleteClick(id: number) {
        this.categoryService.deleteCategory(id).subscribe((category) => {
            this.deleteCategoryImage(id);
            this.loadChapter(this.selectedChapter.id);
            toast('Kategorie wurde erfolgreich gelöscht', 5000);
        }, (error) => {
            toast('Kategorie konnte nicht gelöscht werden', 5000);
        });
    }

    deleteCategoryImage(id: number) {
        this.categoryService.deleteImage(id).subscribe((category) => {
            toast('Das Kategoriebild wurde erfolgreich gelöscht!', 5000);
        }, (error) => {
            toast('Das Kategoriebild konnte nicht gelöscht werden!', 5000);
        });
    }

    handleCategorySaveMode(categorySaveMode: boolean) {
        categorySaveMode ? this.categorySaveMode = false : this.categorySaveMode = true;
    }

    saveNewCategory() {
        this.newCategory.chapterId = this.selectedChapter.id;
        this.newCategory.orderNumber = this.categories.length + 1;
        this.newCategory.imagePath = '';
        this.categoryService.createCategory(this.newCategory).subscribe((category) => {
            this.loadChapter(this.selectedChapter.id);
            this.handleCategorySaveMode(this.categorySaveMode);
            toast('Neue Kategorie wurde erfolgreich erstellt.', 5000);
        }, (error) => {
            toast('Neue Kategorie konnte nicht erstellt werden.', 5000);
        });
    }

    onCategoryEditModal(category: Category) {
        this.selectedEditCategory = category;
    }

    onCategoryEditModalClose() {
        this.selectedEditCategory = null;
    }

    updateCategory() {
        this.categoryService.updateCategory(this.selectedEditCategory).subscribe((category) => {
            this.loadChapter(this.selectedChapter.id);
            toast('Die Kategorieänderugnen wurden erfolgreich gespeichert.', 5000);
            this.selectedEditCategory = null;
        }, (error) => {
            toast('Die Kategorieänderungen konnten nicht gespeichert werden.', 5000);
        });
        if (this.fileToUpload) {
            this.uploadCategoryImage();
        }
    }

    uploadCategoryImage() {
        this.categoryService.postImage(this.selectedEditCategory.id, this.fileToUpload).subscribe(() => {
            this.fileToUpload = null;
            toast('Kategoriebild wurde erfolgreich hochgeladen', 5000);
        }, (error) => {
            toast('Kategoriebild konnte nicht gespeichert werden', 5000);
        });
    }

    ngAfterViewChecked() {
        (<any>$('.modal-trigger')).leanModal();
    }

    ngOnInit() {
        this.route.params.subscribe(p => this.id = p['id']);
        this.refreshBookInfo();
        this.refreshBook();
        this.book = this.bookService.createNew();
        this.newChapter = this.chapterService.createNew();
    }
    switchCover(): void { 
        if (this.appClient === 0){
            this.appClient = 1;
            this.toggleCover = true;
        }
        else {
            this.appClient = 0;
            this.toggleCover = false;
        };
    }
}
