
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDataService } from '../shared/basedata.service';
import { AuthenticationService } from '../auth/authentication.service';

import { Book } from '../model/book';
import { BookInfo } from '../model/book-info';
import { Image } from '../model/image';
import { HttpClient } from '@angular/common/http';
import { AppClient } from 'app/model/app-client';

@Injectable()
export class BookService extends BaseDataService {

    private bookUrl = '/api/book';

    constructor(private http: HttpClient,
        protected authService: AuthenticationService) {
        super(authService)
    }

    public getBookUrl() {
        return super.getFullUrl(this.bookUrl);
    }

    getBooks(): Observable<Book[]> {
        return this.http
            .get<Book[]>(this.getBookUrl(), { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    getBookOverview(): Observable<Book[]> {
      const endpoint = this.getBookUrl() + '/overview';
      return this.http
          .get<Book[]>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    getBook(id: number, appClient: AppClient): Observable<Book> {
      const endpoint = this.getBookUrl() + '/' + appClient + '/' + id;
        return this.http
            .get<Book>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    getBookInfo(id: number): Observable<BookInfo> {
      const endpoint = this.getBookUrl() + '/info/' + id;
        return this.http
            .get<BookInfo>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    getBookInfoByCategory(id: number): Observable<BookInfo> {
        return this.http
            .get<BookInfo>(this.getBookUrl() + '/info/bycategory/ ' + id, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    updateBook(book: Book): Observable<Book> {
        return this.http
            .put<Book>(this.getBookUrl(), JSON.stringify(book), { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    createBook(book: Book): Observable<Book> {
        return this.http
            .post<Book>(this.getBookUrl(), JSON.stringify(book), { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    deleteBook(id: number): Observable<Book> {
      const endpoint = this.getBookUrl() + '/' + id;
        return this.http
            .delete<Book>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    getCover(id: number, appClient: number): Observable<string> {
      const endpoint = this.getBookUrl() + '/' + id + '/' + appClient + '/image';
        return this.http
            .get<string>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    postCover(id: number, image: File, appClient: AppClient): Observable<string> {
      const endpoint = this.getBookUrl() + '/' + appClient + '/' + id + '/image';
        return this.http
            .post<string>(endpoint, image, { headers: this.getUploadHeaders(image.type) }).pipe(
            catchError(this.handleError));
    }

    deleteCover(id: number): Observable<Image> {
      const endpoint = this.getBookUrl() + '/' + id + '/image';
        return this.http
            .delete<Image>(endpoint, { headers: this.getHeaders() }).pipe(
            catchError(this.handleError));
    }

    createNew(): Book {
        return Book.create();
    }
}
